import useLogin from "api/UserResource/useLogin";
import FormField from "components/v2/FormField";
import { Form, Formik } from "formik";
import AuthLayout from "layouts/AuthLayout";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { deviceIdentifiers } from "utils";
import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const Login = () => {
	const [captchaValue, setCaptchaValue] = useState("");

	const captchaHasValue = captchaValue?.length > 0;

	const { os, browser } = deviceIdentifiers();

	const initialValues = {
		username: "",
		password: "",
	};

	const loginSchema = Yup.object().shape({
		username: Yup.string().required("Username/email is required"),
		password: Yup.string().required("Password required"),
	});

	const { mutateAsync, isLoading } = useLogin();
	const handleLogin = async (data) => {
		await mutateAsync({
			...data,
			os,
			browser,
			captcha: captchaValue,
		});
	};

	return (
		<AuthLayout>
			<h1 className="text-akgreen font-cera-black ">Log In</h1>

			{/* <NavLink className="block mt-2" to="/register">
				I don't have an <span className="font-bold">account</span>
			</NavLink> */}

			<div className="mt-2">
				<Formik
					initialValues={initialValues}
					onSubmit={(data) => handleLogin(data)}
					validationSchema={loginSchema}
				>
					{(formik) => (
						<Form className="flex gap-6 flex-col w-full sm:w-80">
							<h4 className="uppercase font-cera-black mb-3">
								Login Information
							</h4>

							<div>
								<FormField
									name="username"
									type="text"
									label="Username/Email*"
								/>
							</div>

							<div>
								<FormField
									name="password"
									type="password"
									label="Password*"
								/>
							</div>

							<div>
								<ReCAPTCHA
									sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
									onChange={(val) => setCaptchaValue(val)}
								/>
							</div>

							<p>
								Forgot password?{" "}
								<Link
									to="/password-reset-options"
									className="font-semibold text-akgreenest hover:text-blue-900"
								>
									Reset here
								</Link>
							</p>

							<div>
								<button
									type="submit"
									className={`px-4 py-2 flex items-center justify-center gap-2 text-white rounded-full text-base ${
										captchaHasValue
											? "bg-gradient-to-r from-akgreen to-akgreenest"
											: "bg-gray-200 cursor-not-allowed text-gray-500"
									}`}
									disabled={!captchaHasValue}
									style={{ minWidth: 150 }}
								>
									Log in
									{isLoading ? (
										<AiOutlineLoading3Quarters
											size={25}
											className="animate-spin"
										/>
									) : undefined}
								</button>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</AuthLayout>
	);
};

export default Login;
