import { useState } from "react";
import useFetchOrders from "api/OrderResource/useFetchOrders";
import Admin2 from "layouts/Admin2";
import useOrdersFilters from "./useOrdersFilters";
import { ButtonLoader, CustomPaginator } from "components";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tag } from "primereact/tag";
import { Calendar } from "primereact/calendar";
import ChangeStatus from "./ChangeStatus";
import moment from "moment";
import { Dropdown } from "primereact/dropdown";
import { activeCurrencies, getPaymentMethodLabel } from "utils";

const Orders = () => {
	const {
		page,
		rowsPerPage,
		firstPage,
		fromDate,
		toDate,
		orderStatus,
		search,
		changePage,
		changeRowsPerPage,
		changeFirstPage,
		changeFromDate,
		changeToDate,
		changeOrderStatus,
		changeSearch,
	} = useOrdersFilters();

	const { data: orders, isLoading: isLoadingOrders } = useFetchOrders({
		page,
		rows_per_page: rowsPerPage,
		from_date: fromDate,
		to_date: toDate,
		order_status: orderStatus,
		search,
	});

	const ordersList = orders?.data?.records;

	const countDefinedFilters = [fromDate, toDate, orderStatus, search].filter(
		(item) => item
	)?.length;

	// Local state for form inputs
	const [formValues, setFormValues] = useState({
		fromDate: fromDate || "",
		toDate: toDate || "",
		orderStatus: orderStatus || "",
		search: search || "",
	});

	const handleInputChange = (name, value) => {
		setFormValues((prev) => ({ ...prev, [name]: value }));
	};

	const handleFormSubmit = (e) => {
		e.preventDefault();
		changeFromDate(formValues.fromDate);
		changeToDate(formValues.toDate);
		changeOrderStatus(formValues.orderStatus);
		changeSearch(formValues.search);
	};

	const handleClearFilters = () => {
		// Clear both form and filter states
		setFormValues({
			fromDate: "",
			toDate: "",
			orderStatus: "",
			search: "",
		});
		changeFromDate("");
		changeToDate("");
		changeOrderStatus("");
		changeSearch("");
	};

	const amountTemplate = (row) => {
		let amountLabel = "-";
		if (row?.grand_total && Number(row?.grand_total) > 0) {
			amountLabel = `${activeCurrencies.USD.label} $${Number(
				row?.grand_total
			)?.toFixed(2)}`;
		} else if (row?.grand_total_zwl) {
			amountLabel = `${activeCurrencies.ZWL.label} $${Number(
				row?.grand_total_zwl
			)?.toFixed(2)}`;
		}

		return <span className="text-base font-bold">{amountLabel}</span>;
	};

	const paymentMethodTemplate = (row) => {
		if (row?.payment_method?.startsWith("Ecocash")) {
			return (
				<div className="flex flex-col">
					<span>{getPaymentMethodLabel(row?.payment_method)}</span>
					<span className="text-xs text-akgreener">{row?.phone}</span>
					{row?.ecocash_reference && (
						<span className="text-xs text-akgreener">
							{row?.ecocash_reference}
						</span>
					)}
				</div>
			);
		}
		return <span>{row?.payment_method}</span>;
	};

	const customerTemplate = (row) => (
		<div className="flex flex-col">
			<span className="font-bold">
				{row?.user?.first_name} {row?.user?.last_name}
			</span>
			<span>{row?.user?.email}</span>
		</div>
	);

	const statusTemplate = (row) => (
		<Tag
			rounded
			className="font-sans text-xs"
			severity={row.status.toLowerCase() === "completed" ? "warn" : "danger"}
			value={row?.status}
		/>
	);

	const actionTemplate = (row) => (
		<ChangeStatus
			orderId={row.id}
			currentOrderStatus={row.status}
			orderNumber={row.order_number}
		/>
	);

	return (
		<Admin2>
			<div className="mt-6 w-full flex-1 flex flex-col justify-between gap-4">
				<form onSubmit={handleFormSubmit}>
					<div className="flex flex-col gap-4">
						<div>
							<h3 className="font-bold">Subscription and purchase orders</h3>
							<p className="font-bold">
								{orders?.data?.records?.length || 0}/
								{orders?.data?.total_records} shown
							</p>
						</div>

						<div className="flex items-end gap-2">
							<input
								type="text"
								name="search"
								id="search"
								placeholder="Search"
								value={formValues.search}
								onChange={(e) => handleInputChange("search", e.target.value)}
								className="border border-gray-300 bg-white rounded h-10 px-2"
							/>

							<Calendar
								value={
									formValues.fromDate ? new Date(formValues.fromDate) : null
								}
								name="from_date"
								dateFormat="yy-mm-dd"
								placeholder="Date (from)"
								className="w-52 h-10 font-sans"
								inputClassName="border border-gray-300 bg-white rounded"
								showIcon
								readOnlyInput
								showButtonBar
								onChange={(e) =>
									handleInputChange(
										"fromDate",
										moment(e.target.value).format("YYYY-MM-DD")
									)
								}
								onClearButtonClick={() => handleInputChange("fromDate", "")}
							/>

							<Calendar
								value={formValues.toDate ? new Date(formValues.toDate) : null}
								name="to_date"
								dateFormat="yy-mm-dd"
								placeholder="Date (to)"
								className="w-52 h-10 font-sans"
								inputClassName="border border-gray-300 bg-white rounded"
								showIcon
								showButtonBar
								onChange={(e) =>
									handleInputChange(
										"toDate",
										moment(e.target.value).format("YYYY-MM-DD")
									)
								}
								onClearButtonClick={() => handleInputChange("toDate", "")}
							/>

							<Dropdown
								value={formValues.orderStatus}
								onChange={(e) => handleInputChange("orderStatus", e.value)}
								options={[
									{ label: "Order status (all)", value: "" },
									{ label: "Completed", value: "Completed" },
									{ label: "Failed", value: "Failed" },
								]}
								optionLabel="label"
								placeholder="Order status"
								className="w-52 h-10 font-sans"
							/>

							<div className="flex items-center gap-2">
								<button
									type="submit"
									className="bg-akgreener text-white px-4 py-2 rounded"
									style={{ width: 114 }}
								>
									Apply Filters
								</button>
								{countDefinedFilters > 0 && (
									<button
										type="button"
										className="bg-gray-300 text-black px-4 py-2 rounded relative"
										style={{ width: 110 }}
										onClick={handleClearFilters}
									>
										Clear Filters
										<span className="absolute -top-3 -right-1 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 bg-akgreenest rounded-full h-6 w-6">
											{countDefinedFilters}
										</span>
									</button>
								)}
							</div>
						</div>
					</div>
				</form>

				<div className="mt-2">
					{isLoadingOrders ? (
						<div className="flex justify-center pt-8">
							<ButtonLoader size={60} />
						</div>
					) : (
						<DataTable
							value={ordersList}
							dataKey="id"
							autoLayout
							tableClassName="font-sans text-sm"
						>
							<Column
								header="Customer"
								body={customerTemplate}
							/>
							<Column
								field="order_number"
								header="Order Number"
							/>
							<Column
								field="reference"
								header="Reference"
							/>
							<Column
								header="Payment method"
								body={paymentMethodTemplate}
							/>
							<Column
								field="payment_type"
								header="Model"
							/>
							<Column
								header="Amount"
								body={amountTemplate}
							/>
							<Column
								field="created_at"
								header="Date created"
							/>
							<Column
								header="Status"
								body={statusTemplate}
							/>
							<Column
								header="Action"
								body={actionTemplate}
							/>
						</DataTable>
					)}
				</div>

				<div className="flex-1 flex items-end justify-end pb-4">
					<CustomPaginator
						firstPage={firstPage}
						setFirstPage={changeFirstPage}
						rowsPerPage={rowsPerPage}
						setRowsPerPage={changeRowsPerPage}
						totalRecords={orders?.data?.total_records}
						setPage={changePage}
						showRowsPerPageDropdown={false}
					/>
				</div>
			</div>
		</Admin2>
	);
};

export default Orders;
