import InitApi from "api/InitApi";
import ServerErrors from "components/SeverErrors";
import { useUrlQuery } from "hooks/useUrlQuery";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import useAuthStore from "store/authStore";
import { deleteEmptyObjects } from "utils";
import toast from "react-hot-toast";
import { reactLocalStorage } from "reactjs-localstorage";
import useTokenStore from "store/tokenStore";

const useLogin = () => {
	const query = useUrlQuery();
	const queryClient = useQueryClient();
	const { accessToken, storeAccessToken } = useTokenStore();

	const {
		storeUser,
		storeUserRoles,
		storeUserPermissions,
		storeUserObjectPermissions,
		storeRefreshToken,
		setSubscriptionStatus,
		setLogInStatusToTrue,
	} = useAuthStore();

	const history = useHistory();

	const login = async (obj) => {
		const instance = await InitApi({ accessToken });
		const body = deleteEmptyObjects(obj);
		const response = await instance.post("/auth/login", body);
		return response;
	};

	return useMutation(login, {
		onSuccess: async ({ data: body }) => {
			queryClient.clear();
			reactLocalStorage.clear();
			storeUser(body?.data?.user);
			storeAccessToken(body?.data?.access_token);
			storeRefreshToken(body?.data?.refresh_token);
			storeUserRoles(body?.data?.user?.role_name);
			storeUserPermissions(body?.data?.user?.permission_names);
			storeUserObjectPermissions(body?.data?.user?.object_permissions);
			setLogInStatusToTrue();
			setSubscriptionStatus(body?.data?.user?.subscriptions.length > 0 ? 1 : 0);
			query?.get("intended")
				? history.push(query?.get("intended"))
				: history.push("/books");
		},
		onError: async (error) => {
			toast.error(
				<ServerErrors
					errorMessage={error?.response?.data.message}
					errors={error?.response?.data?.data}
				/>
			);

			history.push("/login");
		},
	});
};

export default useLogin;
