import InitApi from "api/InitApi";
import React from "react";
import { useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import useAuthStore from "store/authStore";
import useTokenStore from "store/tokenStore";
import { deleteEmptyObjects, fetchConfig } from "utils";

const useSSOLogin = (queryParams, shouldFetch = true) => {
	const queryClient = useQueryClient();
	const { accessToken, storeAccessToken } = useTokenStore();

	const {
		storeUser,
		storeUserRoles,
		storeUserPermissions,
		storeUserObjectPermissions,
		storeRefreshToken,
		setSubscriptionStatus,
		setLogInStatusToTrue,
	} = useAuthStore();

	const history = useHistory();

	const ssoLogin = async () => {
		const instance = await InitApi({ accessToken });
		const params = queryParams ? deleteEmptyObjects(queryParams) : null;
		const res = await instance.get("/sso/auth/login", { params });

		return res.data;
	};

	return useQuery(["sso-login", queryParams], () => ssoLogin(), {
		...fetchConfig,
		enabled: shouldFetch,
		onSuccess: ({ data }) => {
			console.log({ ssoLogin: data });
			queryClient.clear();
			reactLocalStorage.clear();
			storeUser(data.user);
			storeAccessToken(data?.access_token);
			storeRefreshToken(data?.refresh_token);
			storeUserRoles(data?.user?.role_name);
			storeUserPermissions(data?.user?.permission_names);
			storeUserObjectPermissions(data?.user?.object_permissions);
			setLogInStatusToTrue();
			setSubscriptionStatus(data?.user?.subscriptions.length > 0 ? 1 : 0);
		},
	});
};

export default useSSOLogin;
