import InitApi from "api/InitApi";
import { useQuery } from "react-query";
import useTokenStore from "store/tokenStore";
import { deleteEmptyObjects, fetchConfig } from "utils";

const useFetchReadReport = (queryParams) => {
	const { accessToken } = useTokenStore();

	const fetch = async () => {
		const instance = await InitApi({ accessToken });
		const params = queryParams ? deleteEmptyObjects(queryParams) : null;
		const res = await instance.get("read-tracker/dashboard-report", {
			params: { ...params },
		});

		return res.data;
	};

	return useQuery(["ds-read-report", queryParams], () => fetch(), {
		...fetchConfig,
	});
};

export default useFetchReadReport;
